(function($){

    if (!window.VT) {
        window.VT = {};
    }

    VT.AjaxSearch = function(source, target) {
        this.source = source;
        this.target = target;
        this.sourceEl = $(source);
        this.targetEl = $(target);
    };

    VT.AjaxSearch.prototype = {
        init: function() {
            this.sourceEl.find('.js-hide').hide();
            return this;
        },
        replaceParam: function(originalUrl, params) {

            var newUrl = originalUrl;

            params.forEach(function(formData){
                var param = formData.name,
                    formValue = formData.value,
                    re = new RegExp("[\\?&:]" + param + "=([^&#]*)", "i"),
                    match = re.exec(newUrl),
                    delimiter;

                if (match === null) {
                    if (formValue !== '') {
                        var hasQuestionMark = /\?/.test(newUrl);
                        delimiter = hasQuestionMark ? "&" : "?";
                        newUrl = newUrl + delimiter + param + "=" + formValue;
                    }
                }

                else if (match){
                    delimiter = match[0].charAt(0);
                    newUrl = newUrl.replace(match[0], delimiter + param + "=" + formValue);
                }
            });

            return newUrl;
        },
        submit: function() {
            var that = this;

            this.targetEl.find('.sp').remove();
            this.targetEl.prepend('<div class="sp sp-3balls"></div>');

            // Not working yet
            // window.history.pushState && window.history.pushState(null, '', this.replaceParam(window.location, this.sourceEl.serializeArray()));

            $.ajax({
                type: 'GET',
                url: this.sourceEl.attr('action'),
                data: this.sourceEl.serialize(),
                success: function(data) {

                    var response = $(data),
                        form = response.find(that.source),
                        result = response.find(that.target);

                    that.sourceEl.empty().append(form.children());
                    that.targetEl.empty().append(result.children());

                    that.sourceEl.data('ajax-form').init();
                },
                error: function(data) {
                    console.log(data);
                }
            })
        }
    };

    $(document)
        .on('ready', function() {
            $('.js-ajax-search-form').each(function() {
                var self = $(this);
                self.data('ajax-form', new VT.AjaxSearch(self.data('ajax-form-source'), self.data('ajax-form-target')));
                self.data('ajax-form').init();
                self
                    .on('click', '.toggle-click', function() {
                        self.find('.toggle-target').toggleClass('open');
                    })
                    .on('submit', function(e) {
                        e.preventDefault();
                        self.data('ajax-form').submit();
                    })
                    .on('change', '.form-control', function() {
                        self.data('ajax-form').submit();
                    });
            })
        });
})(jQuery);