'use strict';
var InlineEditor = function () {

    /**
     * Javascript part of AjaxController
     */
    var InitAjaxController = function() {
        $(document)
            .on('click', '.btn-ajax-controller', function() {
                var el = $(this);
                $.ajax({
                    url: '/ajax/save',
                    type: 'POST',
                    data: {
                        model: el.data('model'),
                        field: el.data('field'),
                        id: el.data('id'),
                        value: el.data('value'),
                        _token: el.data('token')
                    },
                    headers: {
                        'X-CSRF-TOKEN': el.data('token')
                    },
                    success: function (data) {
                        if (typeof data.markup !== 'undefined') {
                            el.replaceWith(data.markup);
                        }

                        if (typeof data.messages  !== 'undefined') {
                            $('.messages').html(data.messages);
                        }
                    }
                });
            })
    };

    var SaveInlineEditor = function (content) {
        var el = $(content.targetElm);

        $.ajax({
            url: '/ajax/save',
            type: 'POST',
            data: {
                model: el.data('model'),
                field: el.data('field'),
                id: el.data('id'),
                value: el.html(),
                _token: el.data('token')
            },
            headers: {
                'X-CSRF-TOKEN': el.data('token')
            },
            success: function (data) {
            }
        });
    };

    var InitInlineEditor = function() {

        if (typeof tinymce === 'undefined') {
            return;
        }

        tinymce.init({
            selector: 'div.editor-minimal',
            theme: 'modern',
            skin: 'lightgray',
            inline: true,
            toolbar: 'bold italic | forecolor',
            menubar: false,
            force_br_newlines : false,
            force_p_newlines : false,
            forced_root_block : '',
            setup: function(editor) {
                editor.on('focus blur', function(e) {
                    editor.isDirty() && SaveInlineEditor(editor);
                });
            },
            plugins: ['textcolor'],
            valid_elements: '-*[*]',
            save_enablewhendirty: true,
            save_onsavecallback: function(editor) { SaveInlineEditor(editor); }
        });

        tinymce.init({
            selector: 'div.editor-normal',
            inline: true,
            theme: 'modern',
            skin: 'tiny_lightgray',
            removed_menuitems: 'newdocument',
            setup: function(editor) {
                editor.on('focus blur', function(e) {
                    editor.isDirty() && SaveInlineEditor(editor);
                });
            },
            plugins: [
                'autolink link image charmap hr anchor spellchecker',
                'searchreplace wordcount fullscreen insertdatetime media nonbreaking',
                'save table contextmenu directionality emoticons textcolor paste codesample'
            ],
            toolbar: 'codesample | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent |  emoticons | save',
            paste_data_images: true,
            paste_enable_default_filters : false,
            invalid_elements: 'style,scripts',
            save_enablewhendirty: true,
            save_onsavecallback: function(editor) { SaveInlineEditor(editor); }
        });
    };

    return {
        init: function () {
            InitInlineEditor();
            InitAjaxController();
        }
    };
}();


(function ($) {
    $(document)
        .on('ready', function () {
            InlineEditor.init();
        });
})(jQuery);