(function($) {

    if (!window.VT) {
        window.VT = {};
    }

    VT.Logo = {
        logoImage: $('.top-bar .logo'),
        init: function() {
            this.theImage = new Image();
            this.theImage.src = this.logoImage.filter(':first-of-type').attr("src");
            this.logoRatio = this.theImage.width / this.theImage.height;
            return this;
        },
        processLogo: function() {
            if (this.logoRatio > 2.8) {
                this.logoImage.addClass('logo-wide');
            }

            else if (this.logoRatio < 2) {
                this.logoImage.addClass('logo-square');
            }
        }
    };

    $(document)
        .on('ready', function() {
            VT.Logo.init().processLogo();
        });

})(jQuery);