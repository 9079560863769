(function($) {

    if (!window.VT) {
        window.VT = {};
    }

    VT.Navigation = {
        mobileToggle: $('.mobile-toggle'),
        topBar: $('.top-bar'),
        mainContainer: $('.main-container'),
        resizeNav: function() {
            !this.topBar.hasClass('overlay-bar')
                && !this.topBar.hasClass('contained-bar')
                && this.mainContainer.first().css('margin-top', this.topBar.outerHeight());
        }
    };

    $(window)
        .on('resize', function() {
            VT.Navigation.resizeNav();
        })
        .on('scroll', function() {
            $(this).scrollTop() > 500 && !VT.Navigation.mobileToggle.is(":visible")
                ? VT.Navigation.topBar.addClass('nav-sticky')
                : VT.Navigation.topBar.removeClass('nav-sticky');
        });

    $(document)
        .on('ready', function() {
            VT.Navigation.resizeNav();
        })
        .on('click', '.mobile-toggle', function() {
            VT.Navigation.topBar.toggleClass('open-nav');
        });

})(jQuery);