(function($) {

    if (!window.VT) {
        window.VT = {};
    }

    VT.ScrollReveal = {
        config: {
            viewFactor : 0.15,
            duration   : 800,
            distance   : "0px",
            scale      : 0.8
        },
        styles: {
            top: {
                origin   : "top",
                distance : "24px",
                duration : 1500,
                scale    : 1.05
            },
            left: {
                origin   : "left",
                distance : "64px",
                duration : 900,
                scale    : 1
            },
            bottom: {
                origin   : "bottom",
                distance : "64px",
                duration : 900,
                scale    : 1
            },
            right: {
                origin   : "right",
                distance : "64px",
                duration : 900,
                scale    : 1
            }
        },
        init: function(elements) {
            if (elements) {
                this.elements = elements;
            }

            if (!this.sr) {
                this.sr = new ScrollReveal(this.config);
            }
            return this;
        },
        reveal: function() {
            if (this.sr) {
                var that = this;
                this.elements.not('.scrollreveal-processed').each(function() {
                    var self = $(this), direction = that.styles[self.data('direction')] ? that.styles[self.data('direction')] : that.styles.top;
                    that.sr && that.sr.reveal && that.sr.reveal(self, direction);
                    self.addClass('scrollreveal-processed');
                });
            }
        }
    };

    $(document)
        .on('ready', function() {
            VT.ScrollReveal.init($('.js-scroll-reveal')).reveal();
        })
        .on('ajaxComplete', function() {
            VT.ScrollReveal && VT.ScrollReveal.init($('.js-scroll-reveal')).reveal();
        });

})(jQuery);