(function($){

    if (!window.VT) {
        window.VT = {};
    }

    VT.AjaxPager = function(el) {
        this.el = el;
    };

    VT.AjaxPager.prototype = {
        submit: function(url) {
            var that = this;
            this.el.prepend('<div class="sp sp-3balls"></div>');

            $.ajax({
                type: 'GET',
                url: url,
                success: function(data) {

                    var response = $(data),
                        result = response.find('.js-ajax-pager');

                    that.el.empty().append(result.children());
                },
                error: function(data) {
                    console.log(data);
                }
            })
        }
    };

    $(document)
        .on('ready', function() {
            $('.js-ajax-pager').each(function() {
                var self = $(this);
                self.data('ajax-pager', new VT.AjaxPager(self));
            });
        })
        .on('click', '.js-ajax-pager .pagination a', function(e) {
            e.preventDefault();
            var controller = $(this).closest('.js-ajax-pager');
            controller.data('ajax-pager').submit($(this).attr('href'));
        });
})(jQuery);