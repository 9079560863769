$(document).ready(function(){

	"use strict";

	
	// Smooth scroll
	$('.inner-link').smoothScroll({offset: -96, speed: 800});
	

	if (window.scrollReveal) {
		window.scrollReveal.reveal('.js-scroll-reveal', {
			origin   : "top",
			distance : "24px",
			duration : 1500,
			scale    : 1.05,
		});
	}

	// Scroll Reveal
	if (!(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)) {
       //window.scrollReveal = new scrollReveal();
    }else{
    	$('body').addClass('pointer');
    }

	// Slider Initializations

	$('.testimonials-slider').length && $('.testimonials-slider').flexslider({ directionNav: false });
	
	// Slide Sizes
	$('.fullscreen-element').each(function(){
		$(this).css('height', $(window).height());
	});
	
	// Append .background-image-holder <img>'s as CSS backgrounds
	
	$('.background-image-holder').each(function(){
		var imgSrc= $(this).children('img').attr('src');
		$(this).css('background', 'url("' + imgSrc + '")');
    	$(this).children('img').hide();
        $(this).css('background-position', '50% 0%');
	});
	
	/************** Parallax Scripts **************/

    var isFirefox = typeof InstallTrigger !== 'undefined';
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    var isChrome = !!window.chrome;
    var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
    var prefix;

    if (isFirefox) {
        prefix = '-moz-';
    } else if (isIE) {

    } else if (isChrome || isSafari) {
        prefix = '-webkit-';
    }

    $('.main-container section:first-child').addClass('first-child');

    $('.parallax-background').each(function () {

        if ($(this).closest('section').hasClass('first-child') && !$(this).closest('section').hasClass('slider-fullscreen')) {
            $(this).attr('data-top', prefix + 'transform: translate3d(0px,0px, 0px)');
            $(this).attr('data-top-bottom', prefix + 'transform: translate3d(0px,200px, 0px)');

        } else {

            $(this).attr('data-bottom-top', prefix + 'transform: translate3d(0px,-100px, 0px)');
            $(this).attr('data-center', prefix + 'transform: translate3d(0px,0px, 0px)');
            $(this).attr('data-top-bottom', prefix + 'transform: translate3d(0px,100px, 0px)');

        }

    });
    
    if (!(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)) {
        skrollr.init({
            forceHeight: false
        });
        
        // Multi Layer Parallax
    
		$('.hover-background').each(function(){
			$(this).mousemove(function( event ) {
				$(this).find('.background-image-holder').css('transform', 'translate(' + -event.pageX /30 + 'px,' + -event.pageY /45+ 'px)');
				$(this).find('.layer-1').css('transform', 'translate(' + -event.pageX /50 + 'px,' + -event.pageY /50+ 'px)');
				$(this).find('.layer-2').css('transform', 'translate(' + -event.pageX /60 + 'px,' + -event.pageY /60+ 'px)');
			});
		});
    }
    
    // Contact form code

    $('form.email-form').submit(function (e) {
		// return false so form submits through jQuery rather than reloading page.
		if(e.preventDefault) e.preventDefault(); 
		else e.returnValue = false;
		
		var thisForm 		= $(this).closest('.email-form'),
			error 			= 0,
			originalError 	= thisForm.attr('original-error'),
			loadingSpinner;
			
		if (typeof originalError !== typeof undefined && originalError !== false) {
			thisForm.find('.form-error').text(originalError); 
		}
				

		$(thisForm).find('.validate-required').each(function(){
			if($(this).val() === ''){
				$(this).addClass('field-error');
				error = 1;
			}else{
				$(this).removeClass('field-error');
			}
		});
		
		$(thisForm).find('.validate-email').each(function(){
			if(!(/(.+)@(.+){2,}\.(.+){2,}/.test($(this).val()))){
				$(this).addClass('field-error');
				error = 1;
			}else{
				$(this).removeClass('field-error');
			}
		});
		

        if (error === 1){
            $(this).closest('.email-form').find('.form-error').fadeIn(200);
        }else {
			// Hide the error if one was shown
			$(this).closest('.email-form').find('.form-error').fadeOut(200);
			// Create a new loading spinner while hiding the submit button.
			loadingSpinner = $('<div />').addClass('form-loading').insertAfter($(thisForm).find('input[type="submit"]'));
			$(thisForm).find('input[type="submit"]').hide();
            
            jQuery.ajax({
                type: "POST",
                url: "mail/mail.php",
                data: thisForm.serialize(),
                success: function (response) {
                	// Swiftmailer always sends back a number representing numner of emails sent.
					// If this is numeric (not Swift Mailer error text) AND greater than 0 then show success message.
					$(thisForm).find('.form-loading').remove();
					$(thisForm).find('input[type="submit"]').show();
					if($.isNumeric(response)){
						if(parseInt(response) > 0){
							thisForm.find('.form-success').fadeIn(1000);
							thisForm.find('.form-error').fadeOut(1000);
							setTimeout(function(){ thisForm.find('.form-success').fadeOut(500); }, 5000);
						}
					}
					// If error text was returned, put the text in the .form-error div and show it.
					else{
						// Keep the current error text in a data attribute on the form
						thisForm.find('.form-error').attr('original-error', thisForm.find('.form-error').text());
						// Show the error with the returned error text.
						thisForm.find('.form-error').text(response).fadeIn(1000);
						thisForm.find('.form-success').fadeOut(1000);
					}
                },
                error: function (errorObject, errorText, errorHTTP) {
                	// Keep the current error text in a data attribute on the form
					thisForm.find('.form-error').attr('original-error', thisForm.find('.form-error').text());
					// Show the error with the returned error text.
					thisForm.find('.form-error').text(errorHTTP).fadeIn(1000);
					thisForm.find('.form-success').fadeOut(1000);
                	$(thisForm).find('.form-loading').remove();
					$(thisForm).find('input[type="submit"]').show();
                }
            });
        }
		return false;
    });



	// Boot Toggle Confirmation
	var bootstrapToggleConfirmationHandler = function () {
		$(document)
			.on('click.data-toggle', '[data-toggle="confirmation"]', function (e) {
				e.preventDefault();
				e.stopImmediatePropagation();

				var Target = $(this);

				Target.confirmation({
					onConfirm: function () {
						e.isDefaultPrevented = function () {
							return false;
						}
						Target.trigger('click');
					}
				});

				Target.confirmation('show');
			})
	};

	bootstrapToggleConfirmationHandler();

	// Boot typeahead for autocomplete
	var typeaheadHandler = function () {

		var url = "{{ route('typeahead.response') }}";
		$('.autocomplete').each(function () {
			var Target = $(this);
			Target.typeahead({
				source: function (query, process) {

					// Process dependant filters
					var Filters = false;

					if (Target.data('filters')) {
						Filters = Target.data('filters').split(':');
						Filters.map(function (value, key) {
							if (value.toString().indexOf('{') !== -1) {
								var maybeTarget = value.toString().replace('{', '').replace('}', '');
								if ($(maybeTarget).length) {
									Filters[key] = $(maybeTarget).val();
								}
							}
						});

						Filters = Filters.join(':', Filters);
					}


					return $.get(Target.data('url'),
						{
							query: encodeURIComponent(Target.data('query')),
							model: encodeURIComponent(Target.data('model')),
							field: encodeURIComponent(Target.data('field')),
							value: encodeURIComponent(Target.data('value')),
							filters: encodeURIComponent(Filters)

						}, function (data) {

							// Preprocess the data, typeahead only accepts id and name as the object key!
							var processedData = [], fieldKey = Target.data('field'), valKey = Target.data('value');
							$.each(data, function (delta, rawData) {
								processedData[delta] = {
									id: rawData[valKey],
									name: rawData[fieldKey]
								}
							});

							return process(processedData);
						});
				},
				afterSelect: function (selection) {
					if (Target.data('name') && Target.data('value') && typeof selection[Target.data('value')] !== 'undefined') {
						$('[name="' + Target.data('name') + '"]').val(selection[Target.data('value')]);
					}
				}
			});
		});
	};

	$(document)
		.on('keypress', 'input:not(.simple-search)', function(e){
			if ( e.which == 13 ) {
				e.preventDefault();
				return false;
			}
		})
		.on('click', '[data-toggle="open-new-terms"]', function() {
			$(this).parent().find('.createnew-terms').toggleClass('hidden');
		})
		.on('ready', function() {

			typeaheadHandler();

			$('.bs-tree').each(function() {
				var self = $(this), target = self.parent().find('.bs-tree-values');
				self.treeview({
					data: self.data('tree'),
					showCheckbox: true,
					onNodeChecked: function(event, node) {
						target.append('<input type="hidden" class="bs-tree-target" value="' + node.value + '" name="terms[' + node.vocabulary + '][]"/>');


					},
					onNodeUnchecked: function(event, node) {
						target.find('[value="' + node.value + '"]').remove();
					}
				});
			})
		});

});

$(window).load(function(){

  "use strict";
  	
  
	// Align Elements Vertically
	
	alignVertical();
	alignBottom();
	
	$(window).resize(function(){
		alignVertical();
		alignBottom();
	});
    
    // Remove Loader
    
    $('.loader').css('opacity', 0);
    setTimeout(function(){$('.loader').hide();}, 600);
});

function alignVertical(){

		$('.align-vertical').each(function(){
			var that = $(this);
			var height = that.height();
			var parentHeight = that.parent().height();
			var padAmount = (parentHeight / 2) - (height/2);
			that.css('padding-top', padAmount);
		});
	
}

function alignBottom(){
	$('.align-bottom').each(function(){
		var that = $(this);
		var height = that.height();
		var parentHeight = that.parent().height();
		var padAmount = (parentHeight) - (height) - 32;
		that.css('padding-top', padAmount);
	});
}